@import "../../references/index.scss";

@mixin main {
  &.close {
    display: none !important;
  }
  z-index: 100000;
  background-color: rgba($color: $text2, $alpha: 0.64);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
}

@mixin bottom-sheet {
  @include main;
  overflow-y: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none !important;
  }
  &.opening {
    animation: vapp-popup-fade-in 0.3s ease-in-out;
    > .popup-container {
      animation: vapp-popup-drawer-up 0.3s ease-in-out;
    }
  }
  &.closing {
    animation: vapp-popup-fade-out 0.3s ease-in-out;
    > .popup-container {
      animation: vapp-popup-drawer-down 0.3s ease-in-out;
    }
  }
  > .popup-container {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 30vh;
    box-sizing: border-box;
    > .popup-content {
      background-color: white;
      width: 100%;
      border-radius: 12px 12px 0 0;

      > .header-container {
        display: flex;
        margin: 30px 12px;
        justify-content: space-between;

        > h4 {
          font-size: 22px;
          color: $text4;
        }
        button.close-button {
          background-color: $red;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border: none;
          font-size: 16px;
          box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
          border-radius: 5px;
        }
      }
    }
  }
}

@mixin page {
  @include main;
  overflow: hidden;
  display: flex;
  align-items: center;
  &.opening {
    animation: vapp-popup-fade-in 0.3s ease-in-out;
    > .popup-container > .popup-content {
      animation: vapp-popup-scale-up 0.3s ease-in-out;
    }
  }
  &.closing {
    animation: vapp-popup-fade-out 0.3s ease-in-out;
    > .popup-container > .popup-content {
      animation: vapp-popup-scale-down 0.3s ease-in-out;
    }
  }
  > .popup-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow-y: auto;
    box-sizing: border-box;
    > .popup-content {
      background-color: white;
      padding: 20px;
      width: 100%;
      min-height: 100vh;
      position: relative;
      > .header-container {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;

        > h4 {
          font-size: 22px;
          color: $text4;
        }

        > button.close-button {
          background-color: $red;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border: none;
          font-size: 16px;
          box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
          border-radius: 5px;
        }
      }
    }
  }
}
@mixin modal {
  @include main;
  overflow: hidden;
  display: flex;
  align-items: center;
  &.opening {
    animation: vapp-popup-fade-in 0.3s ease-in-out;
    > .popup-container > .popup-content {
      animation: vapp-popup-scale-up 0.3s ease-in-out;
    }
  }
  &.closing {
    animation: vapp-popup-fade-out 0.3s ease-in-out;
    > .popup-container > .popup-content {
      animation: vapp-popup-scale-down 0.3s ease-in-out;
    }
  }
  > .popup-container {
    width: 100%;
    max-height: 100vh;
    position: relative;
    overflow-y: auto;
    padding: 64px 24px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    > .popup-content {
      background-color: white;
      width: 100%;
      max-width: 800px;
      border-radius: 6px;
      overflow-y: auto;
      padding: 30px;

      @include vapp-pre-md {
        padding-left: 18px;
        padding-right: 18px;
      }

      > .header-container {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;

        > h4 {
          font-size: 22px;
          color: $text4;
        }

        > button.close-button {
          background-color: $red;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border: none;
          font-size: 16px;
          box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
          border-radius: 5px;
        }
      }
    }
  }
}
.vapp-popup {
  // > .popup-container > .popup-content button.close-button {
  //   display: none;
  // }
  //================================
  //bottom sheet ===================
  //================================
  &.popup-bottom-sheet {
    @include bottom-sheet;
  }
  &.popup-md-bottom-sheet {
    @include vapp-md {
      @include bottom-sheet;
    }
  }
  &.popup-pre-md-bottom-sheet {
    @include vapp-pre-md {
      @include bottom-sheet;
    }
  }
  //================================
  //modal ==========================
  //================================
  &.popup-modal {
    @include modal;
  }
  &.popup-md-modal {
    @include vapp-md {
      @include modal;
    }
  }
  &.popup-pre-md-modal {
    @include vapp-pre-md {
      @include modal;
    }
  }
  //================================
  //page ===========================
  //================================
  &.popup-page {
    @include page;
  }
  &.popup-md-page {
    @include vapp-md {
      @include page;
    }
  }
  &.popup-pre-md-page {
    @include vapp-pre-md {
      @include page;
    }
  }
}

@keyframes vapp-popup-fade-in {
  0% {
    background-color: rgba($color: $text2, $alpha: 0);
  }
  100% {
    background-color: rgba($color: $text2, $alpha: 0.64);
  }
}
@keyframes vapp-popup-fade-out {
  0% {
    background-color: rgba($color: $text2, $alpha: 0.64);
  }
  100% {
    background-color: rgba($color: $text2, $alpha: 0);
  }
}

@keyframes vapp-popup-drawer-up {
  0% {
    padding-top: 100vh;
  }
  100% {
    padding-top: 30vh;
  }
}
@keyframes vapp-popup-drawer-down {
  0% {
    padding-top: 30vh;
  }
  100% {
    padding-top: 100vh;
  }
}

@keyframes vapp-popup-scale-up {
  0% {
    transform: scale(0.8) translate(0, 200px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes vapp-popup-scale-down {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translate(0, 200px);
  }
}
