@import "../../../utilities/references";

#vekalapp-header-login-modal .popup-content {
  background-color: $bg1;
  .login-content-steps {
    > .phone-step {
      width: 100%;
      > button {
        width: 100%;
        margin-top: 24px;
      }
    }
    > .code-step {
      > p {
        color: $text4;
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 24px;
        width: 100%;
        text-align: center;
      }
      > .code-row {
        display: flex;
        align-items: center;
        justify-content: center;
        direction: ltr;
        input {
          width: 44px;
          height: 44px;
          min-width: 10px;
          margin: 0 16px;
          border-radius: 4px;
          outline: none;
          text-align: center;
          border: none;
          font-family: IRANSansWebFaNum;
          font-weight: 500;
          color: $text4;
          font-size: 14px;
          -moz-appearance: textfield;

          &[type="number"]::-webkit-outer-spin-button,
          &[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
      > button {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}
// .login-content-steps{
// }
