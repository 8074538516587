@import "../utilities/references";
.vekalapp-download-page {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 32px auto;
  padding: 0 32px;
  flex-direction: column;
  @include vapp-md {
    flex-direction: row;
  }
  > div {
    flex: 1;
    display: flex;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
    > h6 {
      @include vapp-size(font-size, 14px, 12px);
      font-weight: 200;
      text-align: center;
    }
    > h2 {
      @include vapp-size(font-size, 26px, 18px);
      font-weight: 600;
      margin: 16px 0;
      text-align: center;
    }
    > h4 {
      @include vapp-size(font-size, 14px, 12px);
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
    @include vapp-md {
      > h2,
      > h4,
      > h6 {
        text-align: right;
      }
      align-items: unset;
    }
    .links {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;
      width: 100%;
      > .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        margin-left: 12px;
        &:last-child {
          margin-left: 0;
        }
        > i {
          font-size: 32px;
          color: gray;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
        }
        > .items-container {
          display: flex;
          flex-direction: column;
          @include vapp-pre-md {
            align-items: center;
          }
          width: 100%;
          > button {
            display: flex;
            align-items: center;
            a {
              display: flex;
              align-items: center;
              width: 100%;
            }
            border: none;
            border-radius: 8px;
            cursor: pointer;
            outline: none;
            background-color: $lightBlue;
            padding: 8px;
            @include vapp-size(margin-bottom, 8px, 12px);
            width: 100%;
            max-width: 200px;
            img {
              @include vapp-size(max-width, 32px, 26px);
              filter: invert(1);
            }
            .text {
              margin-right: 12px;
              display: flex;
              flex-direction: column;
              > h6 {
                font-weight: 400;
                color: white;
                margin-bottom: 4px;
                font-size: 12px;
              }
              > p {
                font-size: 12px;
                font-weight: 200;
                color: white;
              }
            }
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  .image {
    display: flex;
    align-items: center;
    margin: 32px 0;
    @include vapp-md {
      margin: 0;
    }
    > img {
      max-width: 600px;
      width: 100%;
    }
  }
}
