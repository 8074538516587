@import "../../../utilities/references";
#header-profile-menu{
    .item{
        display: flex;
        width: 100%;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 12px;
        color: #626380;
        font-size: 14px;
        font-weight: bold;
        transition: color ease-in-out 0.3;
        &:hover{
            color: $accentBlue;
        }
    }
}