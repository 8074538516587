@import "../../utilities/references";

.vapp-header {
  background-color: white;
  width: 100%;

  @include vapp-pre-md {
    padding: 0 24px;
  }
  .profile {
    background-color: transparent;
    border: 1px solid #171c8f;
    color: #171c8f;
    border-radius: 5px;
    width: 200px;
    height: 42px;
    font-weight: 400;
    outline: none;
    font-size: 14px;
    cursor: pointer;
  }
  > div {
    max-width: 1366px;
    margin: auto;
    padding: 0 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    > h3,
    > a {
      font-size: 12px;
      color: $bg4;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 14px;
      @include vapp-pre-lg {
        padding: 0 8px;
      }

      cursor: pointer;
      position: relative;

      &:hover {
        color: $accentBlue;
      }
      > .popover {
        min-width: 320px;
        z-index: 100;
        padding-top: 18px;
        background-color: #0000;
        border: none;

        .popover-arrow {
          display: none !important;
        }

        .popover-body {
          padding: 0;
        }
      }
    }

    img {
      height: 50px;
      margin-left: 24px;
    }

    // .vapp-input {
    //   max-width: 336px;
    //   min-width: 120px;
    //   flex: 1;

    //   .input-body {
    //     padding: 2px 12px;
    //   }
    // }

    .spacer {
      flex: 1;
    }

    button#go-to-login {
      margin-right: 24px;
      min-width: 120px;
    }
    button.actions {
      width: 45px;
      height: 45px;
      border-radius: 5px;
      background-color: #f1f2f5;
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.person {
        margin-left: 8px !important;
        i {
          color: $accentBlue;
        }
      }
    }
  }
}

#header-news-menu,
#header-tools-menu,
#header-sources-menu {
  width: 200px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  z-index: 100;
  background-color: white;

  .titles {
    padding: 16px 10px;
    width: 100%;

    .subtitle {
      padding: 8px 14px;
      border-radius: 5px;
      width: 100%;
      display: block;
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      h4 {
        font-size: 12px;
        font-weight: bold;
        color: $bg4;
        padding-bottom: 4px;
      }
      h5 {
        font-size: 10px;
        color: $text2;
      }

      &.active {
        background-color: $bg1;
        display: block;

        h4 {
          color: $accentBlue;
        }
        h5 {
          color: $text3;
        }
      }
    }
  }

  // .title-detail {
  //   padding: 6px 18px;
  //   background-color: $bg1;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   display: none;
  //   min-width: 140px;

  //   &.active {
  //     display: flex;
  //   }

  //   > div {
  //     display: flex;
  //     flex-direction: column;
  //   }

  //   a {
  //     padding: 9px;
  //     color: $bg4;
  //     font-size: 12px;

  //     &:hover {
  //       color: $primary;
  //     }
  //   }

  //   .more-details {
  //     a {
  //       color: $accentBlue;
  //     }
  //   }

  //   &.active {
  //     display: flex;
  //   }
  // }
}
