@import "../../utilities/references";

.vapp-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer-banner {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 200px;
        padding: 0 20px;
        margin-top: 220px;

        .banner {
            display: flex;
            width: 100%;
            max-width: 1200px;
            background-color: $accentBlue;
            border-radius: 5px;
            padding: 36px;
            position: relative;

            .bg-image-div {
                position: absolute;
                z-index: 9999;
                right: 0;
                top: 0;
            }

            .image-div {
                position: absolute;
                left: 0;
                bottom: 0;

                img {
                    @include vapp-size(width, 738px, 500px);
                    @include vapp-size(height, 415px, 281);
                }
            }

            .detail {
                display: flex;
                flex-direction: column;

                .b-title {
                    @include vapp-size(font-size, 36px, 24px);
                    font-weight: 500;
                    text-align: right;
                    color: #ffffff;
                    margin-bottom: 24px;
                }

                .b-sub-btn {
                    @include vapp-size(font-size, 26px, 16px);
                    margin-bottom: 24px;
                    background-color: #0f5e89;
                    height: 68px;
                    max-width: 200px;
                    cursor: pointer;
                    z-index: 10000;

                    a {
                        font-weight: normal;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .sticky-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative;


        .sticky-lawyer-search {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            max-width: 1200px;
            position: absolute;
            z-index: 999;
            top: -144px;
            padding: 0 20px;


            .title {
                color: $text4;
                width: 100%;
                display: flex;
                font-weight: 500;
                @include vapp-size(font-size, 28px, 20px);
                @include vapp-size(margin-bottom, 42px, 24px);
            }

            .search-bar {
                display: flex;
                background-color: #f1f2f5;
                align-items: center;
                width: 100%;
                padding: 30px;
                column-gap: 38px;
                border-radius: 5px;
                box-shadow: 0 -18px 42px 0 rgba(22, 23, 52, 0.02);

                .d-province, .d-expert {
                    flex: 2;
                }

                .vekalapp-single-dropdown {
                    .index {
                        margin-top: 0 !important;
                    }
                }

                .vapp-input {
                    margin-bottom: 0 !important;
                }

                .vapp-dropdown {
                    margin-bottom: 0 !important;
                }

                .searching-btn {
                    display: flex;
                    flex: 1;

                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

        .main-footer {
            display: flex;
            width: 100%;
            background-color: $text4;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            position: relative;

            .has-sticky {
                padding-top: 80px !important;
            }

            .footer-content {
                display: flex;
                max-width: 1200px;
                margin: auto;
                column-gap: 20px;
                padding: 40px 20px 40px 20px;
                flex-wrap: wrap;
                row-gap: 36px;

                .info {
                    display: flex;
                    flex-direction: column;
                    flex: 2;
                    width: 100%;
                    color: #ffffff !important;

                    .logo-div {
                        display: flex;
                        column-gap: 20px;
                        align-items: center;

                        img {
                            width: 180px;
                            height: 88px;
                        }
                    }

                    .desc {
                        margin-bottom: 16px;
                        line-height: 2.2;
                        font-size: 16px !important;
                        font-weight: normal;
                        letter-spacing: normal;
                        text-align: right;
                        min-width: 250px;
                    }

                    .num-email {
                        display: flex;
                        column-gap: 36px;
                        align-items: center;
                        flex-wrap: wrap;

                        .num, .email {
                            display: flex;
                            align-items: center;
                            //flex-wrap: wrap;

                            i, a {
                                direction: ltr;
                                font-weight: 300;
                                color: #ffffff;
                                font-size: 16px;
                            }

                            a {
                                cursor: pointer;
                            }

                            i {
                                margin-left: 12px;
                            }
                        }
                    }
                }

                .service, .access {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: 100%;

                    .title {
                        font-weight: normal;
                        font-size: 18px;
                        text-align: right;
                        border-bottom: 0.5px solid #ffffff;
                        max-width: fit-content;
                        padding-bottom: 6px;
                        color: #ffffff;
                        margin-bottom: 16px;
                    }

                    .item {
                        font-weight: 300;
                        font-size: 16px;
                        text-align: right;
                        margin-bottom: 16px;
                        color: #ffffff;
                        min-width: 140px;

                        &:hover {
                            color: $accentBlue;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .social-media {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    flex: 2;

                    .register-news {
                        display: flex;
                        position: relative;

                        .send-btn {
                            position: absolute;
                            width: 34px;
                            height: 34px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 41px;
                            z-index: 999;
                            left: 8px;

                            i {
                                font-size: 20px;
                            }
                        }

                        .vapp-input {
                            margin-bottom: 26px !important;
                            position: relative !important;

                            .label {
                                color: #ffffff !important;
                                font-size: 18px !important;
                            }
                        }
                    }


                    .social {
                        font-size: 18px;
                        font-weight: normal;
                        text-align: right;
                        color: #ffffff;
                        margin-bottom: 26px;
                    }

                    .icon-div {
                        display: flex;
                        column-gap: 20px;

                        a {
                            .icon {
                                font-size: 20px;

                                &:hover {
                                    color: $accentBlue !important;
                                }
                            }
                        }

                        i {
                            font-size: 20px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}